import { createRouter,createWebHistory} from "vue-router";

const router=createRouter({
    history:createWebHistory(),

    routes:[
        {
            path:'/index/',
            redirect:''
        },
        {
            path:'',
            component:()=>import('@/components/HomePage'),
        },
        {
            path:'/ip/',
            component:()=>import('@/components/IP'),
        },
        {
            path:'/brand/',
            component:()=>import('@/components/BrandPage')
        },
        {
            path:'/franchisee/',
            component:()=>import('@/components/FranchiseePage')
        },
        {
            path:'/connection/',
            component:()=>import('@/components/CompanyConnection')
        },
        {
            path:'/FW',
            component:()=>import('@/components/FW')
        },
        {
            path:'/store',
            component:()=>import('@/components/StorePage')
        },
        {
            path:'/note',
            component:()=>import('@/components/Index/YzlItem')
        },
        {
            path:'/welfare/',
            component:()=>import('@/components/WelfarePage')
        },
        {
            path:'/video1/',
            component:()=>import('@/components/VideoPage1')
        },
        {
            path:'/video2/',
            component:()=>import('@/components/VideoPage2')
        },
        {
            path: '/mobile/',
            component: () => import('@/components/mobile/MobileHomePage')
        },
        {
            path: '/news/',
            component: () => import('@/components/newsPage')
        },
        {
            path: '/authorization/',
            component: () => import('@/components/authorizationPage')
        },
        {
            path: '/star/',
            component: () => import('@/components/StarPage')
        },
        {
            path: '/product/',
            component: () => import('@/components/YfPage')
        },
        {
            path: '/video/',
            component: () => import('@/components/videoPage')
        },
        {
            path: '/mobilestar/',
            component: () => import('@/components/mobile/MobileStarPage')
        },
        {
            path: '/mobilebrand/',
            component: () => import('@/components/mobile/BrandPage')
        },
        {
            path: '/mobilevideo/',
            component: () => import('@/components/mobile/MobileVideo')
        },
        {
            path: '/mobileYF/',
            component: () => import('@/components/mobile/MobileYF')
        },
        {
            path: '/myPicture/',
            component: () => import('@/components/MyPicture')
        },
        {
            path: '/products/',
            component: () => import('@/components/MainProducts')
        },
        {
            path: '/honor/',
            component: () => import('@/components/HonorPage')
        },
        {
            path: '/mobilewelfare/',
            component: () => import('@/components/mobile/MobileWelfare')
        },
        {
            path: '/mobilehonor/',
            component: () => import('@/components/mobile/MobileHonor')
        },
        {
            path: '/mobileproduct/',
            component: () => import('@/components/mobile/MobileProduct')
        },
    ]
})

export default router;